import { useCallback } from 'react'
import { useClerk, useSession as useClerkSession } from '@clerk/nextjs'

export {
  ClerkProvider,
  SignInButton,
  UserButton,
  UserProfile,
  useClerk,
} from '@clerk/nextjs'

export function useSession() {
  const session = useClerkSession()
  const clerk = useClerk()
  // eslint-disable-next-line -- this is correctly bound
  return { ...session, buildSignInUrl: clerk.buildSignInUrl }
}

export const DRIVE_SCOPE = 'https://www.googleapis.com/auth/drive.file'

export const useGoogleScope = () => {
  const { session, isSignedIn } = useClerkSession()

  const needsConnection = useCallback(() => {
    if (!isSignedIn) {
      return true
    }

    const googleAccount = session.user.externalAccounts.find(
      (account) => account.provider === 'google'
    )
    if (!googleAccount) {
      return true
    }

    return false
  }, [session, isSignedIn])

  const needsAuthorization = useCallback(
    (scope?: string) => {
      if (!isSignedIn) {
        return true
      }

      if (needsConnection()) {
        return true
      }

      const googleAccount = session.user.externalAccounts.find(
        (account) => account.provider === 'google'
      )
      if (!googleAccount) {
        return true
      }

      if (
        googleAccount.verification?.expireAt &&
        googleAccount.verification.expireAt < new Date()
      ) {
        return true
      }

      return Boolean(scope && !googleAccount.approvedScopes.includes(scope))
    },
    [session, needsConnection, isSignedIn]
  )

  const connectAccount = async ({
    scope,
    redirectUrl,
    onSuccess,
  }: {
    scope: string
    redirectUrl: string
    onSuccess?: (verificationUrl: string) => void
  }) => {
    if (!isSignedIn) {
      throw new Error('User is not signed in')
    }

    if (needsConnection()) {
      // link google account and ask for scope
      const res = await session.user.createExternalAccount({
        strategy: 'oauth_google',
        additionalScopes: [scope],
        redirectUrl,
      })

      if (res.verification?.externalVerificationRedirectURL?.href) {
        onSuccess &&
          onSuccess(res.verification.externalVerificationRedirectURL.href)
      }
    }
  }

  const reauthorizeAccount = async ({
    scope,
    redirectUrl,
    onSuccess,
  }: {
    scope: string
    redirectUrl: string
    onSuccess?: (verificationUrl: string) => void
  }) => {
    if (!isSignedIn) {
      throw new Error('User is not signed in')
    }

    if (needsAuthorization(scope)) {
      // google account is already linked, just ask for scope
      const googleAccount = session.user.externalAccounts.find(
        (account) => account.provider === 'google'
      )
      if (googleAccount) {
        const res = await googleAccount.reauthorize({
          additionalScopes: [scope],
          redirectUrl,
        })

        if (res.verification?.externalVerificationRedirectURL?.href) {
          onSuccess &&
            onSuccess(res.verification.externalVerificationRedirectURL.href)
        }
      }
    }
  }

  return {
    needsConnection,
    needsAuthorization,
    connectAccount,
    reauthorizeAccount,
  }
}
