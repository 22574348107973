import type { ThemeConfig } from '@/components/providers/ThemeProvider/config'

export function themeTypography(theme: ThemeConfig) {
  return {
    fontFamily: theme.fontFamily,
    h5: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.14286,
      color: theme.colors.grey[600],
      textTransform: 'uppercase' as const,
    },
    h4: {
      fontSize: '1.125rem',
      color: theme.heading,
      fontWeight: 500,
      lineHeight: 1.33333,
    },
    h3: {
      fontSize: '1.25rem',
      color: theme.heading,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      color: theme.heading,
      fontWeight: 600,
      lineHeight: 1,
    },
    h1: {
      fontSize: '2rem',
      color: theme.heading,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    button: {
      textTransform: 'none' as const,
    },
  }
}
