import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { componentStyleOverrides } from './override'
import { colors, config } from './config'
import { themeTypography } from './typography'

const themeOptions = {
  palette: colors,
  mixins: {
    toolbar: {
      minHeight: '48px',
      padding: '16px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
  shape: {
    borderRadius: config.borderRadius,
  },
  typography: themeTypography(config),
}

const theme = responsiveFontSizes(createTheme(themeOptions))
theme.components = componentStyleOverrides(config)

export { theme }

declare module '@mui/material/styles' {
  interface TypeBackground {
    panel1: string
    panel2: string
    panel3: string
  }
}
