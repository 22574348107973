'use client'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import { useAtomValue } from 'jotai'
import { Alert, Grow } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { saveState } from './state'

interface Props {
  children: React.ReactNode
}

function LoadingIcon() {
  return (
    <Box sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
      <CircularProgress color="info" size={14} />
    </Box>
  )
}

function SavingInfo() {
  const MINIMUM_VISIBLE_TIME = 400
  const isOpen = useAtomValue(saveState)
  const [timeOpened, setTimeOpened] = useState<number>()
  const [isReallyOpen, setIsReallyOpen] = useState(false)

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined)

  useEffect(() => {
    if (isOpen) {
      setTimeOpened(Date.now())
      setIsReallyOpen(true)
    }

    if (!isOpen && timeOpened) {
      const elapsedTime = Date.now() - timeOpened
      if (elapsedTime > MINIMUM_VISIBLE_TIME) {
        setIsReallyOpen(false)
      } else {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
          setIsReallyOpen(false)
          setTimeOpened(undefined)
        }, MINIMUM_VISIBLE_TIME - elapsedTime)
      }
    }
  }, [isOpen, timeOpened])

  return (
    <Snackbar
      TransitionComponent={Grow}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isReallyOpen}
      transitionDuration={200}
    >
      <Alert icon={<LoadingIcon />} severity="info">
        Saving
      </Alert>
    </Snackbar>
  )
}

export function SavingIndicatorProvider({ children }: Props) {
  return (
    <>
      <SavingInfo />
      {children}
    </>
  )
}
