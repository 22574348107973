import { Roboto } from 'next/font/google'

export const colors = {
  background: {
    paper: '#ffffff',
    default: 'rgb(238, 242, 246)',
    panel3: '#E7EAFE',
    panel2: '#F7F8FC',
    panel1: '#FFFFFF',
  },

  primary: {
    main: '#4050B5',
    light: '#3C4DF5',
  },

  secondary: {
    main: '#1D2429',
    light: '#455662',
  },

  success: {
    light: '#b9f6ca',
    200: '#69f0ae',
    main: '#00e676',
    dark: '#00c853',
  },
  error: {
    main: '#E00E01',
  },
  orange: {
    light: '#fbe9e7',
    main: '#ffab91',
    dark: '#d84315',
  },

  warning: {
    light: '#fff8e1',
    main: '#ffe57f',
    dark: '#ffc107',
  },

  grey: {
    50: '#f8fafc',
    100: '#E4E5E9',
    200: '#D6D8EB',
    300: '#cdd5df',
    500: '#697586',
    600: '#455662',
    700: '#364152',
    900: '#121926',
  },
}

const font = Roboto({
  subsets: ['latin'],
  display: 'swap',
  weight: ['100', '300', '400', '500', '700', '900'],
})

export const config = {
  colors,
  heading: colors.grey[900],
  paper: colors.background.paper,
  backgroundDefault: colors.background.paper,
  background: colors.primary.light,
  darkTextPrimary: colors.grey[700],
  darkTextSecondary: colors.grey[500],
  textDark: colors.grey[900],
  divider: colors.grey[200],
  fontFamily: font.style.fontFamily,
  borderRadius: 4,
}

export type ThemeConfig = typeof config
